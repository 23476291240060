* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #f5f5f5;
}

a {
  text-decoration: none;
}

.navbar {
  z-index: 1; /* Asegura que la barra de navegación esté por encima del overlay */
}

.image-container {
  position: relative;
}

/* Estilos para la imagen */
.custom-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.homepage {
  background-image: url('../media/background.jpg');
  background-size: cover; 
  background-repeat: no-repeat; 
  min-height: 90vh;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Color y opacidad del overlay */
  pointer-events: none; /* Permite que los elementos debajo del overlay sean clickeables */
}


/* Estilos para el texto */
.image-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente para mejorar la legibilidad del texto */
  color: #fff; /* Color del texto */
  padding: 10px; /* Espaciado interior para mejorar la presentación */
  text-align: center; /* Centrar el texto horizontalmente */
}
/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*/

body {
  margin: 0;
  padding: 0; 
}
